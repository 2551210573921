




















































































import { defineComponent } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import store from '@/store'
import router from '@/router'
import { PaginationOptions, parseQuery, usePagination } from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import { userConnectedCanChangeRole } from '@/composables/UserGrant'

export default defineComponent({
  setup (props, ctx) {
    const query = {
      ...router.app.$route.query,
    }
    const totalItems = () => {
      return ctx.root.$appConfig.roles.length
    }
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      }: PaginationOptions = usePagination(
      totalItems,
      parseQuery(query),
    ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
    }
  },
  name: 'ListAssociateGrant',
  components: {
    TopBar,
    Pagination,
  },
  data () {
    return {
      title: this.$t('user_group.title_header'),
      headers: [
        {
          value: 'checkbox',
          sortable: false,
        },
        {
          text: this.$t(
            'roles.headers.role',
          ),
          align: 'start',
          value: 'role',
        },
      ],
      isSubmiting: false,
      checkedRoles: [] as string[],
    }
  },
  created () {
    this.checkedRoles = this.userGroup.roles
  },
  computed: {
    ...mapState('userGroupForm', {
      userGroup: 'userGroup',
    }),
    ...mapGetters('navigation', {
      getLastRouteUserGroup: 'getLastRouteUserGroup',
    }),
  },
  methods: {
    userCanChangeRole: userConnectedCanChangeRole,
    ...mapMutations('userGroupForm', {
      setRoles: 'setRoles',
    }),
    ...mapActions('userGroupForm', {
      resetUserGroup: 'reset',
    }),
    reset () {
      this.checkedRoles = []
    },
    associateCheckedGrantToUserGroup () {
      this.isSubmiting = true
      this.setRoles(this.checkedRoles)

      this.$router.push(this.getLastRouteUserGroup)
    },
  },
  beforeRouteEnter (to, from, next) {
    store.commit('navigation/addRoute', to)
    next()
  },
  /*
   */
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'UserGroup Create') {
      store.commit('navigation/removeRoute')
    }
    if (to.name !== 'UserGroup Edit' && to.name !== 'UserGroup Create') {
      this.resetUserGroup()
    }
    next()
  },
})
